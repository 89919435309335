import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';
import {
  FirebaseStorageService,
  FormOf,
  GeoPoint,
  MissionCommonAttributesForm,
  MissionIntroForm,
  MissionOutroForm,
  TranslatedForm,
} from '@freddy/common';
import {
  DeleteMissionAction,
  EditMissionAction,
  EditMissionByIdAction,
  MissionCreatedAction,
  MissionUpdatedAction,
  NewMissionAction,
  RemoveOutro,
  ResetMissionFormAction,
  SaveMissionAction,
  ToggleOutroEnabledAction,
  UpdateMissionPositionAction,
} from '../actions/mission.actions';
import { MissionRepository } from '../repository/mission.repository';
import { DataMapper } from '../../../core/utils/data.mapper';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import {
  DeleteTempMarker,
  EditScenarioAction,
  ResetScenarioFormAction,
  SaveScenarioAction,
} from '../../scenario/actions/scenario.actions';
import { Router } from '@angular/router';
import { RouterUtils } from '../../../core/utils/router.utils';
import {
  Mission,
  MissionIntro,
  MissionMetadata,
  MissionOutro,
  MissionTypeEnum,
  TranslatedContent,
} from '@freddy/models';
import { AssetService, AssetType } from '../../../core/assets/asset.service';
import { firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AssetRepository } from '../../../core/assets/asset.repository';

export const MISSION_STATE_TOKEN = new StateToken<MissionStateModel>('mission');

export interface MissionStateModel {
  uid?: string;
  missions: Mission[];
  layout: {
    loading: boolean;
  };
  common: FormOf<MissionCommonAttributesForm>;
  intro: TranslatedForm<MissionIntroForm>;
  outro: TranslatedForm<MissionOutroForm>;
  metadata: FormOf<MissionMetadata>;
  outroEnabled?: boolean;
}

const defaultValue: Partial<MissionStateModel> = {
  uid: undefined,
  layout: {
    loading: false,
  },
  common: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  intro: {},
  outro: {},
  metadata: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  outroEnabled: false,
};

@State<MissionStateModel>({
  name: MISSION_STATE_TOKEN,
  defaults: {
    ...(defaultValue as MissionStateModel),
    missions: [],
  },
})
@Injectable()
export class MissionState {
  private readonly storageService = inject(FirebaseStorageService);
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly assetsService = inject(AssetService);
  private readonly assetRepository = inject(AssetRepository);
  private readonly missionRepository = inject(MissionRepository);

  @Selector()
  static outroEnabled(state: MissionStateModel): boolean {
    return state.outroEnabled || false;
  }

  @Selector()
  static isNewMission(state: MissionStateModel): boolean {
    return !state.uid;
  }

  @Selector()
  static missionType(state: MissionStateModel): MissionTypeEnum | undefined {
    return state.metadata.model?.missionType;
  }

  @Selector([MissionState])
  static missionReadOnlyLocations(state: MissionStateModel): GeoPoint[] {
    return state.missions
      .map((m) => {
        return m.common.location;
      })
      .filter((geo): geo is GeoPoint => !!geo)
      .filter((loc) => {
        return !(
          loc?.lat === state.common.model?.location?.lat &&
          loc?.lon === state.common.model?.location?.lon
        );
      });
  }

  @Selector()
  static currentRadius(state: MissionStateModel): number | undefined {
    return state.common.model?.radius;
  }

  @Selector()
  static geolocalizedMissions(state: MissionStateModel): Mission[] {
    return state.missions.filter((mission) => mission.common.geolocalized);
  }

  @Selector()
  static missionsBonus(state: MissionStateModel): Mission[] {
    return state.missions.filter((mission) => !mission.common.geolocalized);
  }

  @Selector()
  static missions(state: MissionStateModel): Mission[] {
    return state.missions;
  }

  @Selector()
  static isLoading(state: MissionStateModel): boolean {
    return state.layout.loading;
  }

  @Action(NewMissionAction)
  newMissionAction(
    ctx: StateContext<MissionStateModel>,
    action: NewMissionAction,
  ) {
    return this.store.dispatch(new ResetMissionFormAction()).pipe(
      tap(() => {
        this.router.navigate(['new'], {
          relativeTo: RouterUtils.getActivatedRoute(this.router),
        });
      }),
    );
  }

  @Action(EditScenarioAction)
  async editScenario(
    ctx: StateContext<MissionStateModel>,
    action: EditScenarioAction,
  ) {
    ctx.patchState({
      missions: [...action.scenario.missions],
    });
  }

  @Action(ResetScenarioFormAction)
  async resetMissionFormForm(ctx: StateContext<MissionStateModel>) {
    ctx.patchState({
      ...defaultValue,
    });
  }

  @Action(MissionCreatedAction)
  missionCreated(
    ctx: StateContext<MissionStateModel>,
    action: MissionCreatedAction,
  ) {
    ctx.setState(
      patch<MissionStateModel>({
        missions: append<Mission>([action.mission]),
      }),
    );
    ctx.dispatch(new SaveScenarioAction(true));
  }

  @Action(ResetMissionFormAction)
  resetMissionForm(ctx: StateContext<MissionStateModel>) {
    ctx.patchState(defaultValue);
  }

  @Action(UpdateMissionPositionAction)
  updateMissionPositionAction(
    ctx: StateContext<MissionStateModel>,
    action: UpdateMissionPositionAction,
  ) {
    const state = ctx.getState();
    const missionToUpdate = state.missions.find(
      (mission) => mission.uid === action.missionUid,
    );
    if (missionToUpdate) {
      const updatedMission: Mission = {
        ...missionToUpdate,
        common: {
          ...missionToUpdate!.common,
          location: action.coordinates,
        },
      };
      ctx.setState(
        patch<MissionStateModel>({
          missions: updateItem<Mission>(
            (mission) => mission?.uid === updatedMission.uid,
            updatedMission,
          ),
        }),
      );
      return ctx.dispatch(new SaveScenarioAction(true));
    }
    return;
  }

  @Action(MissionUpdatedAction)
  missionUpdated(
    ctx: StateContext<MissionStateModel>,
    action: MissionUpdatedAction,
  ) {
    ctx.setState(
      patch<MissionStateModel>({
        missions: updateItem<Mission>(
          (mission) => mission?.uid === action.mission.uid,
          action.mission,
        ),
      }),
    );
    ctx.dispatch(new SaveScenarioAction(true));
  }

  @Action(SaveMissionAction)
  async saveMission(
    ctx: StateContext<MissionStateModel>,
    action: SaveMissionAction,
  ) {
    const state = ctx.getState();
    if (state.common.model?.location)
      ctx.dispatch(new DeleteTempMarker(state.common.model?.location));
    ctx.patchState({
      layout: {
        ...state.layout,
        loading: true,
      },
    });
    const common = state.common.model;
    const metadata: MissionMetadata = {
      ...state.metadata.model,
      outroEnabled: state.outroEnabled ?? false,
    } as MissionMetadata;

    if (common && metadata) {
      if (state.uid) {
        const mission = await this.missionRepository.updateMission({
          uid: state.uid,
          common: common,
          intro: DataMapper.mapTranslatedFormToContent(state.intro),
          outro: DataMapper.mapTranslatedFormToContent(state.outro),
          metadata: metadata,
        });
        this.store.dispatch(new MissionUpdatedAction(mission));
      } else {
        const mission = await this.missionRepository.createMission({
          common: common,
          intro: DataMapper.mapTranslatedFormToContent(state.intro),
          outro: DataMapper.mapTranslatedFormToContent(state.outro),
          metadata: metadata,
        });
        this.store.dispatch(new MissionCreatedAction(mission));
      }
    }
    return this.store.dispatch(new ResetMissionFormAction());
  }

  @Action(EditMissionByIdAction)
  async editMissionById(
    ctx: StateContext<MissionStateModel>,
    action: EditMissionByIdAction,
  ) {
    const missionToEdit = ctx.getState().missions.find((mission) => {
      return mission.uid === action.missionId;
    });
    if (missionToEdit) {
      this.store.dispatch(new EditMissionAction(missionToEdit));
    }
  }

  @Action(ResetScenarioFormAction)
  async resetScenarioForm(ctx: StateContext<MissionStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      missions: [],
    });
  }

  @Action(EditMissionAction)
  async editMission(
    ctx: StateContext<MissionStateModel>,
    action: EditMissionAction,
  ) {
    const state = ctx.getState();
    this.resetMissionForm(ctx);
    ctx.patchState({
      layout: {
        ...state.layout,
        loading: true,
      },
    });

    const introAssets = await firstValueFrom(
      this.assetsService.getAssets(action.mission, AssetType.INTRO).pipe(
        map((assets) => {
          return this.assetsService.getTranslatedAssets(assets);
        }),
      ),
    );

    const outroAssets = await firstValueFrom(
      this.assetsService.getAssets(action.mission, AssetType.OUTRO).pipe(
        map((assets) => {
          return this.assetsService.getTranslatedAssets(assets);
        }),
      ),
    );

    const missionIntroForm: TranslatedContent<MissionIntroForm> =
      await DataMapper.addAssetsToTranslatedContent<
        MissionIntro,
        MissionIntroForm
      >(action.mission.intro, introAssets, 'images', this.storageService);

    const missionOutroForm: TranslatedContent<MissionOutroForm> =
      await DataMapper.addAssetsToTranslatedContent<
        MissionOutro,
        MissionOutroForm
      >(action.mission.outro, outroAssets, 'images', this.storageService);

    ctx.patchState({
      layout: {
        loading: false,
      },
      uid: action.mission.uid,
      common: DataMapper.getFormOf({
        ...action.mission.common,
      }),
      metadata: DataMapper.getFormOf({
        ...action.mission.metadata,
      }),
      intro: DataMapper.mapTranslatedContentToForm(missionIntroForm),
      outro: DataMapper.mapTranslatedContentToForm(missionOutroForm),
      outroEnabled: action.mission.metadata.outroEnabled,
    });
  }

  @Action(DeleteMissionAction)
  deleteMissionAction(
    ctx: StateContext<MissionStateModel>,
    action: DeleteMissionAction,
  ) {
    ctx.setState(
      patch<MissionStateModel>({
        missions: removeItem<Mission>(
          (mission) => mission.uid === action.mission.uid,
        ),
      }),
    );
    action.mission.assets.forEach((assetUid) => {
      this.assetRepository.delete({
        uid: assetUid,
      });
    });
    if (action.disableSavingScenario) {
      return;
    }
    ctx.dispatch(new SaveScenarioAction(true));
  }

  @Action(RemoveOutro)
  removeOutro(ctx: StateContext<MissionStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      outro: {},
    });
  }

  @Action(ToggleOutroEnabledAction)
  toggleOutroEnabledAction(
    ctx: StateContext<MissionStateModel>,
    action: ToggleOutroEnabledAction,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      outroEnabled: action.enabled,
    });
  }
}
